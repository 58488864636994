import {getFromAny} from "../composables/getFromAny.js";
import {showError, useRequestEvent} from 'nuxt/app';
import {useBreadcrumbStore} from "../stores/BreadcrumbStore.js";
import {usePageMetadata} from "../composables/useMeta.js";
import {gtm} from '../composables/gtm.js';

export default defineNuxtRouteMiddleware(async (to, from) => {

    const routerStore = useRouterStore();
    const pathsAreEqual = to.path === from.path;
    const queryString = pathsAreEqual && Object.keys(to.query).length > 0 ? new URLSearchParams(to.query).toString() : '';
    const apiUrl = `router${queryString ? '?' + queryString : ''}`;

    async function fetchPageType(path, query = {}) {
        if (!routerStore.hasPage(path)) {
            let data = null;
            if (process.server && useRequestEvent().context.pageInfo) {
                data = useRequestEvent().context.pageInfo.data;
            } else {
                // try {
                    data = await getFromAny(apiUrl, {
                        method: 'POST',
                        body: {path},
                        query
                    });
                // } catch (e) {
                //     console.log(e);
                //     showError(e);
                //     //return abortNavigation();
                // }
            }

            if (!data.url) {
                data.url = path;
            }

            if (data.name !== 'undefined') {
                routerStore.addPage(data);
            } else {
                //client navigation for legacy
                routerStore.addPage(data); //prevents loop
                if (process.client) {
                    // if (to.matched[0].name.startsWith('slug___')) {
                       window.location.href = path;
                       return;
                    // }
                }
                throw createError({ statusCode: 404, statusMessage: 'Page not found' })
            }
            return data;
        }
        return routerStore.getPage(path);
    }


    const pageType = await fetchPageType(to.path, to.query);

    if (pageType?.name !== 'undefined') {
        gtm.openPage(pageType.name);
    }

    //product/catalog
    if (pageType?.url && pageType.url !== to.path) {
        return navigateTo(pageType.url, { redirectCode: 301 });
    }

    usePageMetadata().initializePageMetadata(pageType);
    useBreadcrumbStore().items = pageType?.breadcrumbs;
});
